/*
 * MainFrame.js:    This is the main UI container that includes layout, states and controls.
 *                  This UI is a proof of concept as a single-page-app.
 *                  It is divided into 4 sections (divs) that are displayed based on the app's progress.
 *                  The transitions from one section to another is based on user interaction or API fetch state.
 */
import React, {useEffect, useState} from 'react';

import Div1InputEmail from './Div1InputEmail/Div1InputEmail';
import Div2Searching from './Div2UserInfo/Div2Searching';
import Div2Error from './Div2UserInfo/Div2Error';
import Div2UserFound from './Div2UserInfo/Div2UserFound';
import Div3MfaSelection from './Div3VerifyIdentity/Div3MfaSelection';
import Div4MessageArea from './Div4MessageArea/Div4MessageArea';
import Div3aPassCodeInput from './Div3VerifyIdentity/Div3aPassCodeInput';
import Div3ManagerSelection from './Div3VerifyIdentity/Div3ManagerSelection';

import useSearchUser from '../hooks/useSearchUser';
import usePushMfa from '../hooks/usePushMfa';
import useSubmitCode from '../hooks/useSubmitCode';
import useVoiceSms from '../hooks/useVoiceSms';
import useVerifySite from '../hooks/useVerifySite';
import useEmailUser from '../hooks/useEmailUser';
import useEmailManager from '../hooks/useEmailManager';
import useValidateIdentity from "../hooks/useValidateIdentity";
// import useRemoveExternalEmail from "../hooks/useRemoveExternalEmail";

import './MainFrame.css';
import { removeExternalEmail } from '../api/email';


const MainFrame = () => {

    // States for MainFrame
    const [activeStep, setActiveStep] = useState(1);        // Track the active step so that we can highlight in UI where we are (1= InputEmail)

    // States for Div1 (Input email)
    const [enteredEmail, setEnteredEmail] = useState('');    // Track what a user inputs in the email field

    // States for Div2 (User info and buttons)
    const [isDiv2Visible, setDiv2Visible] = useState(false);     // Turns visible after Div1 Search button press
    const [div2FetchStatus, setDiv2FetchStatus] = useState('');    // '', 'searching'   , 'data'       , 'error'
    const [div2Title, setDiv2Title] = useState('');                // '', 'Searching...', 'User Found!', 'Error!'
    const [userInfo, setUserInfo] = useState({});   // {lastName, firstName, email, email2}
    const [div2Error, setDiv2Error] = useState('');      // Set only if there's an error
    const [div2ButtonLabel, setDiv2ButtonLabel] = useState('');    // 'Reset Password' or 'Set 2nd Email'

    // States for Div3 (Verify User prompt)
    const [isDiv3Visible, setDiv3Visible] = useState(false);    // Turns visible only after Div2 "Reset Password" button press

    // States for Div3 (MFA)
    const [showCodeInput, setShowCodeInput] = useState(false);  // Whether to display the authenticator code input
    const [passCode, setPassCode] = useState('');       // The 6-digit code from the app associated with the selected MFA factor
    const [pushMfaStep, setPushMfaStep] = useState(0);  // 0 (init), 1, 2, 3, 4, 9 (error)
    const [telecomMfa, setTelecomMfa] = useState(false);    // True if voice or SMS is selected
    const [otherMfa, setOtherMfa] = useState(false);        // True if webauthn, external_idp, signed_nonce is selected
    const [activeMfaData, setActiveMfaData] = useState({});      // {userFirstName, userId, factorId, factorType, ...}
    const [apiProgress, setApiProgress] = useState(false);
    const [sessionId, setSessionId] = useState('');         // For TOTP/Voice/SMS: Final session ID from /verify_response
    const [voiceSmsSessionId, setVoiceSmsSessionId] = useState('');  // Voice/SMS: First session ID from /trigger_challenge
    const [numPasscodeDigits, setNumPasscodeDigits] = useState(6);

    // States for Div3 (Manager selection)
    const [workflow, setWorkflow] = useState(1);
    const [shouldFetchManager, setShouldFetchManager] = useState(false);
    const [selectedManager, setSelectedManager] = useState({});

    // Identity Validation
    const [identityStep, setIdentityStep] = useState(0);    // 0 (init), 1, 2, 3 (complete), 9 (error)
    const [identityProgress, setIdentityProgress] = useState(false);

    // States for Div4 (Transitional messages and the final OK button)
    const [isDiv4Visible, setDiv4Visible] = useState(false);    // Turns visible only after Div3 "Verify User" button press
    const [div4Title, setDiv4Title] = useState('');               //
    const [div4Message, setDiv4Message] = useState('');
    const [div4Error, setDiv4Error] = useState(false);
    const [div4ButtonVisible, setDiv4ButtonVisible] = useState(false);

    // Custom hooks
    useValidateIdentity(identityStep, setIdentityStep, userInfo, setDiv4Message, setDiv4Title, setDiv4Error);
    usePushMfa(pushMfaStep, setPushMfaStep, activeMfaData, setDiv4Message);
    useVerifySite(otherMfa, activeMfaData, setDiv4Message, setDiv4Error, setDiv4ButtonVisible);
    useVoiceSms(telecomMfa, activeMfaData, setDiv4Message, setShowCodeInput, setVoiceSmsSessionId);
    const {searchUser} = useSearchUser(setActiveStep, setDiv2Visible, setDiv2FetchStatus, setDiv2Title, setUserInfo, setDiv2Error);
    const {submitCode} = useSubmitCode(passCode, activeMfaData, voiceSmsSessionId);
    const {emailUserIdVerify} = useEmailUser(sessionId, activeMfaData, setDiv4Message, setDiv4Error, setDiv4ButtonVisible);
    const {emailManager: emailManagerIdVerify} = useEmailManager(2, selectedManager, userInfo, setDiv4Message, setDiv4Error, setDiv4ButtonVisible);
    const {emailManager: emailManager2ndEmail} = useEmailManager(3, selectedManager, userInfo, setDiv4Message, setDiv4Error, setDiv4ButtonVisible);
    // const {resetExternalEmail} = useRemoveExternalEmail(userInfo,  setDiv2FetchStatus, setDiv2Title, setUserInfo, setDiv2Error);

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length > 0) {
            if (userInfo?.okta?.secondEmail === 'NO_EMAIL') {
                setDiv2ButtonLabel('Set Secondary email');
                setWorkflow(3);
            } else if (userInfo?.okta?.secondEmail.includes('@')) {
                setDiv2ButtonLabel('Reset Password');
                setWorkflow(1);
            }
        }
    }, [userInfo]);

    useEffect(() => {
        if (sessionId) {
            emailUserIdVerify();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    useEffect(() => {
        if (selectedManager && Object.keys(selectedManager).length > 0) {
            if (workflow === 2) {
                emailManagerIdVerify();
            } else if (workflow === 3) {
                emailManager2ndEmail();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedManager]);

    /**
     * sleep: A utility function to pause the execution for a given time.
     * @param { } ms 
     * @returns 
     */
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    /**
     * Reset all states to the initial values.
     */
    const resetState = () => {
        console.log('MainFrame: resetState: Resetting states...');
        setActiveStep(1);   // Go back to email input
        setEnteredEmail('');

        setDiv2Visible(false);
        setDiv2FetchStatus('');
        setDiv2Title('');
        setUserInfo({});
        setDiv2Error('');

        setDiv3Visible(false);
        setShowCodeInput(false);
        setPushMfaStep(0);
        setTelecomMfa(false);
        setOtherMfa(false);
        setApiProgress(false);
        setWorkflow(1);

        setSessionId('');
        setVoiceSmsSessionId('');
        setSelectedManager({});

        setDiv4Visible(false);
        setDiv4Title('');
        setDiv4Message('');
        setDiv4Error(false);
        setDiv4ButtonVisible(false);

        setIdentityStep(0);
        setIdentityProgress(false);
    };

    /*
     *******************************************************************************************************************
     * Div1: Input Email
     *******************************************************************************************************************
   */
    const Div1SearchButtonClicked = async () => {
        // First, ensure that all other divs are hidden
        setDiv3Visible(false);
        setDiv4Visible(false);
        setShowCodeInput(false);

        await searchUser(enteredEmail);
        // NOTE: userInfo will be set in searchUser(), but it's not populated yet in the next line
        console.log(`MainFrame:Div1SearchButtonClicked: userInfo= ${JSON.stringify(userInfo)}`);
    };

    /*
     *******************************************************************************************************************
     * Div2: Display user info, or error if not found
     *******************************************************************************************************************
   */

    const handleValidateIdButtonPress = async () => {
        console.log(`MainFrame:handleValidateIdButtonPress`);
        setDiv3Visible(false);  // Hide Div3 if visible (the area for MFA or Manager selection)
        setDiv4Visible(true);   // Show Div4 (the area for the message)
        setDiv4Error(false);
        setShowCodeInput(false);

        setActiveStep(4);
        setIdentityProgress(true);
        setIdentityStep(1)  // This will trigger useValidateIdentity
    }

    const handleDiv2ButtonPress = () => {
        setDiv3Visible(true);
        setDiv4Visible(false);
        setDiv4Error(false);
        setDiv4ButtonVisible(false);
        setActiveStep(3);

        if (div2ButtonLabel === 'Reset Password') {
            Div2ResetPasswordClicked();
        } else {   // 'Set Secondary email'
            console.log('MainFrame:handleDiv2ButtonPress: Setting Workflow to 3...');
            Div2Set2ndEmailClicked();
        }
    }

    const handleEmailResetButtonPress = async () => {
        setDiv3Visible(false);
        setDiv4Visible(false);
        setDiv4Error(false);
        setDiv4ButtonVisible(false);
        setDiv2Title('Removing Secondary Email...');
        setApiProgress(true);

        await removeExternalEmail(userInfo.okta.id,  setDiv2FetchStatus, setDiv2Title, setUserInfo, setDiv2Error);
        setDiv2Title('Secondary Email Removed');
        setDiv2Error('');
        userInfo.okta.secondEmail = 'NO_EMAIL';
        console.log(`MainFrame:handleEmailResetButtonPress: userId= ${JSON.stringify(userInfo.okta.id)}`);
        

        // Wait for 2 seconds before searching the user again
        await sleep(2000);
        await searchUser(enteredEmail);
        setApiProgress(false);
    }



    const Div2ResetPasswordClicked = () => {
        setShowCodeInput(false);
        setPushMfaStep(0);
        console.log(`MainFrame:Div2ResetPasswordClicked: Setting apiProgress to FALSE...`)
        setApiProgress(false);
        setWorkflow(1);



    };

    const Div2Set2ndEmailClicked = () => {

        setShouldFetchManager(true);
        setWorkflow(3);
    };

    // If user wasn't found or error occurred, 'Start over'
    const Div2StartOverClicked = () => {
        resetState();
    };

    /*
    *******************************************************************************************************************
    * Div3: One of the 3 cases:
    *      Workflow 1. Use to identify themselves by MFA
    *      Workflow 2. Manager to verify user's identity
    *      Workflow 3. Manager to add user's secondary email
    *******************************************************************************************************************
   /*
    *********************************************************************
    * Workflow 1: User self-verifies their identify by MFA
    *********************************************************************
   */
    // Callback from Div3MfaSelection. Receives a factor ID of the selected MFA method.
    const onSelectMfaFactor = (selectedFactorId) => {

        setWorkflow(1);

        // Extract the selected MFA object by the factor ID from the array of available MFA objects
        const selectedMfaObject = userInfo.factors.find(item => item.id === selectedFactorId);
        console.log(`MainFrame: onSelectMfaFactor: selectedMfaObject= ${JSON.stringify(selectedMfaObject)}`);   // This is correct

        setActiveMfaData({                // Set all data necessary for API calls here so that we don't need to pass the entire userInfo or factorInfo
            userFirstName: userInfo.firstName,
            userEmail: userInfo.email,
            userId: userInfo.okta.id,
            factorId: selectedMfaObject.id,
            factorType: selectedMfaObject.factorType,
            factorName: selectedMfaObject.name
        });

        // For Voice & SMS: First push the challenge (6-digit code), and then display the code input
        if (selectedMfaObject.factorType === 'call' || selectedMfaObject.factorType === 'sms') {
            setNumPasscodeDigits(selectedMfaObject.factorType === 'call' ? 5 : 6);
            console.log(`MainFrame:onSelectMfaFactor: Setting apiProgress to true...`)
            setApiProgress(true);
            setDiv4Visible(true);
            setTelecomMfa(true);    // This will trigger useVoiceSms hook
        }
        // For TOTP: Display the 6-digit code input UI
        else if (selectedMfaObject.factorType.includes('token')) {
            // For token-based MFA, we don't want to set apiProgress until the correct code is submitted.
            setTelecomMfa(false);
            setNumPasscodeDigits(6);
            console.log(`MainFrame: onSelectMfaFactor: Displaying code input...`);
            setDiv4Visible(false);
            setShowCodeInput(true); // This will render the code input UI
            // The rest will be done in onCodeEntered()
        }
        // For all other factorTypes
        else {
            console.log(`MainFrame:onSelectMfaFactor: Setting apiProgress to true...`)
            setApiProgress(true);
            setTelecomMfa(false);
            setShowCodeInput(false);
            setDiv4Visible(true);
            setDiv4Error(false);
            setDiv4ButtonVisible(false);
            setActiveStep(4);

            // For Push: Send the challenge
            if (selectedMfaObject.factorType === 'push') {
                console.log(`MainFrame: onSelectMfaFactor: OktaVerify Push selected`);
                setPushMfaStep(1); // This will trigger usePushMfa hook
            } else {       // For webauthn, external_idp, signed_nonce: Instruct ServiceDesk that a user visits the auth page
                setOtherMfa(true);      // This will trigger useVerifySite hook
                // TODO: Find a better way to trigger it. Can we directly call it here?
            }
        }
    };

    // Callback from sendPasscode() in Div3aPassCodeInput.js
    const onCodeEntered = async (code) => {
        console.log(`MainFrame: onCodeEntered: code= ${code}, voiceSmsSessionId= ${voiceSmsSessionId}`);
        setPassCode(code);

        // Close the code input UI and display the message area
        setDiv4Visible(true);
        setDiv4Error(false);
        setActiveStep(4);

        // Submit code to backend. submitCode() takes care of all the way to sending the email.
        setDiv4Message(`Checking the validity of the code...`);

        const response = await submitCode(code);
        if (response.error) {
            console.log(`MainFrame: onCodeEntered: Error from submitCode: ${response.error}`);
            setDiv4Error(true);
            setDiv4Message(response.error);
            if (response.error.includes('User Locked')) {    // In this case, we want to start over
                setDiv4ButtonVisible(true);
            }
            // For other errors, such as "Invalid Passcode/Answer", we want to retry
        } else if (response.sessionId) {    // For all cases, set sessionId returned by /verify_response
            setSessionId(response.sessionId);   // This will trigger emailUserIdVerify() in useEffect(if sessionId)
            setShowCodeInput(false);
            setApiProgress(true);
        }
    };

    const Div3UseManagerVerifyClicked = async () => {
        console.log('MainFrame: Div3UseManagerVerifyClicked: Overwriting Div3...');
        setDiv3Visible(true);
        setShowCodeInput(false);
        setDiv4Visible(false);
        setWorkflow(2);
        setShouldFetchManager(true);
    };

    /*
     *********************************************************************
     * Workflow 2: Manager verifies user's identify
     * Workflow 3: Manager adds user's secondary email
     *********************************************************************
    */
    const onSelectManager = async (manager) => {
        console.log(`MainFrame:onSelectManager: workflow= ${workflow}`);
        console.log(`MainFrame:onSelectManager: manager= ${JSON.stringify(manager)}`);
        setSelectedManager(manager);
        setActiveStep(4);
        setDiv4Visible(true);
    };

    const Div4OkClicked = () => {
        resetState();
    };

    return (
        <div className='main-wrapper'>

            <Div1InputEmail
                enteredEmail={enteredEmail}
                setEnteredEmail={setEnteredEmail}
                Div1SearchButtonClicked={Div1SearchButtonClicked}
                setUserInfo={setUserInfo}
                setDiv2Visible={setDiv2Visible}
                setDiv3Visible={setDiv3Visible}
                setDiv4Visible={setDiv4Visible}
                setDiv4Title={setDiv4Title}
                setDiv4Error={setDiv4Error}
                setDiv4ButtonVisible={setDiv4ButtonVisible}
                setShowCodeInput={setShowCodeInput}
                apiProgress={apiProgress}
                identityProgress={identityProgress}
                setApiProgress={setApiProgress}
                setIdentityProgress={setIdentityProgress}
                setIdentityStep={setIdentityStep}
                activeStep={activeStep}
            />

            {isDiv2Visible && div2FetchStatus === 'searching' && (
                <Div2Searching div2Title={div2Title} activeStep={activeStep}/>
            )}

            {isDiv2Visible && div2FetchStatus === 'data' && (
                <Div2UserFound div2Title={div2Title}
                               userInfo={userInfo}
                               activeStep={activeStep}
                               apiProgress={apiProgress}
                               identityProgress={identityProgress}
                               div2ButtonLabel={div2ButtonLabel}
                               handleDiv2ButtonPress={handleDiv2ButtonPress}
                               handleValidateIdButtonPress={handleValidateIdButtonPress}
                               handleEmailResetButtonPress={handleEmailResetButtonPress}
                />
            )}

            {isDiv2Visible && div2FetchStatus === 'error' && (
                <Div2Error div2Title={div2Title} errorMsg={div2Error}
                           Div2StartOverClicked={Div2StartOverClicked}
                           activeStep={activeStep}
                />
            )}

            {isDiv3Visible && workflow === 1 && (
                <Div3MfaSelection
                    mfaData={userInfo.factors}
                    onSelectMfa={onSelectMfaFactor}
                    activeStep={activeStep}
                    apiProgress={apiProgress}
                    Div3UseManagerVerifyClicked={Div3UseManagerVerifyClicked}
                />
            )}

            {showCodeInput && (
                <Div3aPassCodeInput
                    onCodeEntered={onCodeEntered}
                    activeStep={activeStep}
                    numDigits={numPasscodeDigits}
                />
            )}

            {isDiv3Visible && workflow === 2 && shouldFetchManager && (
                <Div3ManagerSelection
                    shouldFetchManager={shouldFetchManager}
                    enteredEmail={enteredEmail}
                    activeStep={activeStep}
                    onSelectManager={(manager) => onSelectManager(manager, 2)}
                    title='Manager Must Verify Identity'
                    buttonLabel='Send Identity Verification'
                    action='Verifying identity'
                />
            )}

            {isDiv3Visible && workflow === 3 && shouldFetchManager && (
                <Div3ManagerSelection
                    shouldFetchManager={shouldFetchManager}
                    enteredEmail={enteredEmail}
                    activeStep={activeStep}
                    onSelectManager={(manager) => onSelectManager(manager, 3)}
                    title='Manager Must Set Secondary Email'
                    buttonLabel='Send Notification Email'
                    action='Adding Secondary email'
                />
            )}

            {isDiv4Visible && (
                <Div4MessageArea
                    message={div4Message}
                    div4Title={div4Title}
                    div4Error={div4Error || pushMfaStep === 9 || identityStep === 9}
                    isDiv4ButtonVisible={div4ButtonVisible || pushMfaStep === 4 || pushMfaStep === 9 || identityStep === 3 || identityStep === 9}
                    activeStep={activeStep}
                    Div4OkClicked={Div4OkClicked}
                />
            )}
        </div>
    );
};

export default MainFrame;
