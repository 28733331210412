import React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Security} from "@okta/okta-react";
import {OktaAuth} from "@okta/okta-auth-js";
import oktaConfig from "./oktaConfig";
import Home from './Home';
import Login from './Login';
import SecureRoute from './SecureRoute';
import NikeLoginCallback from './NikeLoginCallback';
import './App.css';

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigate(originalUri || '/');
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/callback" element={<NikeLoginCallback/>}/>
                <Route path="/" element={<SecureRoute><Home/></SecureRoute>}/>
            </Routes>
        </Security>
    );
}

export default App;
