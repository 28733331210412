/* eslint-disable no-unused-vars */
import React from 'react';
import { LoginCallback } from '@okta/okta-react';

const NikeLoginCallback = () => {
    
    const renderError = (props) => {
        const { error } = props;
        if (error) {
            const { name, errorCode, errorSummary } = error;

            if (errorSummary === 'User is not assigned to the client application.') {  //customized error handling
                return (
                    <div style={{textAlign: 'center', marginTop: 50}}>
                        <span className='errorTitle'>Access Denied</span>
                        <p>This tool is for the use of the Nike Service Desk team only.</p>
                        <p>For self-service password reset, please visit <a style={{textDecoration: 'underline'}} href="https://nike.okta.com/signin/forgot-password">https://nike.okta.com/signin/forgot-password</a></p>
                    </div>
                );
            }

            //generic error handling
            return (
                <div style={{textAlign: 'center', marginTop: 50}}>
                    <div className='errorTitle'>Error</div>
                    <p>{errorSummary}</p>
                </div>
            );
        }

        return null;
    };
    return <LoginCallback errorComponent={renderError} />;
};

export default NikeLoginCallback;
