/*
 * isEmailValid.js: This function checks if the email address has a valid format.
 *
 * Email address must have at least one character before the @ sign, and must end with @nike.com or @converse.com
 */
const isEmailValid = (email) => {

    // Only these domains are allowed
    const validDomains = ['nike.com', 'converse.com'];

    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check the general email format
    if (!emailRegex.test(email)) {
        return false;
    }

    // Check the domain
    const domain = email.split('@')[1];
    return validDomains.includes(domain);
}

export default isEmailValid;
