import findUserByEmail from '../api/users';

const useSearchUser = (setActiveStep, setDiv2Visible, setDiv2FetchStatus, setDiv2Title, setUserInfo, setDiv2Error) => {

    const searchUser = async (enteredEmail) => {
        console.log(`useSearchUser: searchUser: enteredEmail= ${enteredEmail}`);
        setActiveStep(2);
        setDiv2Visible(true);
        setDiv2FetchStatus('searching');
        setDiv2Title('Searching for the user...');

        const {errorMsg, userInfo} = await findUserByEmail(enteredEmail);

        if (userInfo) {
            setDiv2FetchStatus('data');
            setDiv2Title('User Found!');
            setUserInfo(userInfo);
            setDiv2Error('');
        } else if (errorMsg) {
            setDiv2FetchStatus('error');
            setDiv2Title('Error!');
            setUserInfo(null);
            setDiv2Error(errorMsg);
        } else {
            console.log('useSearchUser:searchUser: *** Unexpected! No data or error message from findUserByEmail()');
        }
    };

    return {searchUser};
};

export default useSearchUser;
