/*
 * Div2UserFound: This component displays the user information.
 *
 * It receives the entire userInfo returned by GET /user?user=${email}.
 * This component/UI can decide what to display.
 */
import React from 'react';
import './Div2UserInfo.css';

const Div2UserFound = ({
                           div2Title,
                           userInfo,
                           activeStep,
                           apiProgress,
                           identityProgress,
                           div2ButtonLabel,
                           handleDiv2ButtonPress,
                           handleValidateIdButtonPress,
                           handleEmailResetButtonPress
                       }) => {


    if (!userInfo) {
        return null;    // Don't render anything if userInfo isn't loaded yet
    }

    if (div2ButtonLabel === '') {   // Don't render anything if the button label is empty
        return null;
    }

    // If the user is deprovisioned, we need to disable the buttons
    let userDisabled = false;
    if (userInfo?.okta?.status === 'DEPROVISIONED') {
        userDisabled = true;
        userInfo.okta.status = 'DISABLED';
    }
    console.log('Div2UserFound: userDisabled=', userDisabled);

    // If this is a contractor and they have a secondary email, allow the SD to reset it
    let allowResetSecondaryEmail = false;
    if (userInfo?.job?.employeeType === 'Contractor' && userInfo?.okta?.secondEmail.includes('@')) {
        allowResetSecondaryEmail = true;
    }
    console.log('Div2UserFound: allowResetSecondaryEmail=', allowResetSecondaryEmail);

    // Calculate the time when the user can change the password again
    var recentPasswordChange = false;
    const lastPasswordChangeTime = new Date(userInfo?.activeDirectory?.passwordLastSet);
    const validPasswordChangeTime = new Date(lastPasswordChangeTime.getTime() + 24 * 60 * 60 * 1000);
    const validPasswordChangeTimeStr = validPasswordChangeTime.toLocaleString();
    const now = Date.now();
    if (now < validPasswordChangeTime) {
        recentPasswordChange = true;
    }
    console.log('Div2UserFound: recentPasswordChange=', recentPasswordChange);
    console.log('Div2UserFound: validPasswordChangeTime=', validPasswordChangeTime);

    // If the user doesn't have any MFA factors, alert the SD user
    let hasMfaFactors = false;
    if (userInfo?.factors) {
        let counter = 0;
        for (const factor of userInfo.factors) {
            if (factor.status === 'ACTIVE' && factor.factorType !== 'manager') {
                counter++;
            }
        }
        if (counter > 0) {
            hasMfaFactors = true;
        }
    }
    console.log('Div2UserFound: hasMfaFactors=', hasMfaFactors);

    console.log('Div2UserFound: userInfo=', userInfo);

    return (
        <div className={`child-div ${activeStep === 2 ? 'active-step' : ''}`}>
            <div className='app-div-subtitle'>{div2Title}</div>
            <div className='user-info-container app-text'>
                <div className="user-info"><strong>Name: </strong>{userInfo.firstName} {userInfo.lastName}</div>
                <div className="user-info"><strong>Account Status: </strong>{userInfo.okta?.status}</div>
                <div className="user-info"><strong>Type: </strong>{userInfo.job?.employeeType}</div>
                <div className="user-info">
                    <strong>Location: </strong>{userInfo.location.city}, {userInfo.location.state}, {userInfo.location.countryCode}
                </div>
                <div className="user-info"><strong>Building: </strong>{userInfo.location.officeLocation}</div>
                <div className="user-info"><strong>Title: </strong>{userInfo.job?.title}</div>
                <div className="user-info"><strong>Department: </strong>{userInfo.job?.department}</div>
                <div className="user-info"><strong>Email: </strong> {userInfo.email}</div>
                <div className="user-info"><strong>Last Password Change: </strong>{lastPasswordChangeTime.toLocaleString()}</div>
                <div className="user-info"><strong>Secondary email: </strong>{userInfo.okta?.secondEmail}</div>
            </div>

            <div className='user-account-disabled-alert' hidden={!userDisabled}>
                <div className='alert-title'>Account Disabled</div>
                 <div className='alert-text'>The user's manager must follow the instructions in <a className="alert-link" href="https://niketech.service-now.com/now/nav/ui/classic/params/target/kb_view.do%3Fsysparm_article%3DKB0020736" target="_blank" rel="noreferrer">KB0020736</a> to re-enable this account.</div>
            </div>

            <div className='user-mfa-factors-alert' hidden={userDisabled || hasMfaFactors}>
                <div className='alert-title'>No MFA enrolled</div>
                 <div className='alert-text'>This user has no MFA factors enrolled. <b>To enroll an MFA factor, you must be on the Nike network.</b> If the user is not on the Nike network and cannot use GlobalProtect, see <a className='alert-link' href="https://niketech.service-now.com/kb_view.do?sysparm_article=KB0020749" target="_blank" rel="noreferrer" >KB0020749</a></div>
            </div>

            <div className='user-password-age-alert' hidden={!recentPasswordChange}>
                <div className='alert-title'>Recent Password Change</div>
                 <div className='alert-text'>This user's password was recently changed. No password changes/resets will be possible until <span className='bold'>{validPasswordChangeTimeStr}</span> due to the Minimum Password Age policy, which is currently set to 1 day</div>
            </div>

            <button
                className={`app-button button-under  ${(apiProgress || identityProgress || userDisabled) ? 'button-invalid' : 'button-valid'}`}
                disabled={apiProgress || identityProgress || userDisabled}
                onClick={handleValidateIdButtonPress}
            >Validate Identity
            </button>

            <button
                className={`app-button button-under ${(apiProgress || identityProgress || userDisabled || recentPasswordChange) ? 'button-invalid' : 'button-valid'}`}
                disabled={apiProgress || identityProgress || userDisabled || recentPasswordChange}
                onClick={handleDiv2ButtonPress}
            >{div2ButtonLabel}
            </button>

            <button
                hidden={!allowResetSecondaryEmail}
                className={`app-button button-under ${(apiProgress || identityProgress) ? 'button-invalid' : 'button-valid'}`}
                disabled={apiProgress || identityProgress}
                onClick={handleEmailResetButtonPress}
            >Reset Secondary Email
            </button>

        </div>
    );
};

export default Div2UserFound;
